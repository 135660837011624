<template>
  <div class="imgLoaded">
    <div class="container">
      <div class="grid-background dashboard border-radius home-cards">
        <div v-for="widget in widgets" class="home-card-wrapper">
          <div class="home-card">
            <router-link :to="widget.path" class="Home_item">
              <div class="square">
                <i v-if="Array.isArray(widget.img)" :class="widget.img"></i>
                <img
                  v-else-if="widget.img"
                  @load="imgLoaded"
                  :src="widget.img"
                  alt=""
                />
                <div class="menu-button-text">{{ widget.title }}</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="../assets/home.css"></style>
<style>
@media screen and (min-width: 750px) {
  .row-desktop {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .adminHome {
    display: grid;
    align-items: center;
  }
}
</style>

<script>
import PermisionsMixin from "@/mixins/allPermMixin.js";
import updateRoleStatus from "../mixins/updateUserRoleStatus.js";
import { mapGetters } from "vuex";

import I1 from "@/assets/media/widgets/propuneri.svg";
import I2 from "@/assets/media/widgets/referate.svg";
import I3 from "@/assets/media/widgets/nevoi.svg";
// import I4 from '@/assets/media/produse.svg'
// import I5 from '@/assets/media/unitati_masura.svg'
// import I6 from '@/assets/media/money.svg'
// import I7 from '@/assets/media/procedure_type.svg'
// import I8 from '@/assets/media/institutions.svg'
// import I9 from '@/assets/media/departments.svg'
import I10 from "@/assets/media/widgets/proiecte.svg";

const showProjects = !!appconfig.VUE_APP_PROIECTE;
const showAchizitions = !!appconfig.VUE_APP_ACHIZITII;

const roleMap = ["superadmin", "admin_institutie", "responsabil_achizitii"];

//{
//   perm: 'products',
//   path: '/catalog',
//   title: 'Catalog',
//   img: I4,
//   order: 5,
//   forAdmin: true
// },
// {
//   perm: 'cpvCategories',
//   path: '/categorii_cpv',
//   title: 'Categorii CPV',
//   img: ['fas fa-list-alt'],
//   order: 6,
//   forAdmin: true
// },
// {
//   perm: 'measurmentUnits',
//   path: '/unitati_masura',
//   title: 'Unități de măsură',
//   img: I5,
//   order: 7,
//   forAdmin: true
// },
// {
//   perm: 'budgetSources',
//   path: '/surse_buget',
//   title: 'Surse buget',
//   img: I6,
//   order: 8,
//   forAdmin: true
// },
// {
//   perm: 'procedureTypes',
//   path: '/tip_proceduri',
//   title: 'Tipuri de proceduri',
//   img: I7,
//   order: 9,
//   forAdmin: true
// },
// {
//   perm: 'institutions',
//   path: '/institutii',
//   title: 'Instituții',
//   img: I8,
//   order: 10,
//   forAdmin: true
// },
// {
//   perm: 'departments',
//   path: '/departamente',
//   title: 'Departamente',
//   img: I9,
//   order: 11,
//   forAdmin: true
// },
// {
//   perm: 'currency',
//   path: '/valute',
//   title: 'Valute',
//   img: ['fas fa-coins'],
//   order: 12
// },
// {
//   perm: 'register',
//   path: '/registrul_pragurilor',
//   title: 'Registrul pragurilor',
//   img: ['fas fa-book'],
//   order: 13
// }

export default {
  $Title: "ConectX",
  mixins: [PermisionsMixin, updateRoleStatus],

  data() {
    return {
      widgets: [],
      adminWidgets: [],
      placehold: `<div class="square inactive_section">
          <i class="far fa-clock"></i>
          <span class="menu-button-text">În curând</span>
          <div class="ribbon ribbon-top-right"><span>În lucru</span></div>
        </div>`,
      widgetMap: [
        {
          perm: "profile",
          path: "/profil",
          title: "Profil",
          img: ["fas fa-user-tie"],
          order: 0,
        },
        {
          perm: "projects",
          path: "monitorizare/proiecte",
          title: "Monitorizare",
          img: I10,
          order: 4,
          SHOW: showProjects,
        },
        {
          perm() {
            let x = "needs|needReference";
            if (!this.adminPagesAllowed) x = "products|" + x;

            return x;
          },
          path() {
            let x = "/planificare/nevoi|/planificare/referate";
            if (!this.adminPagesAllowed) x = "/planificare/catalog|" + x;

            return x;
          },
          title: "Planificare",
          img: I3,
          order: 1,
          SHOW: showAchizitions,
        },
        {
          perm: "assignments",
          path: "/atribuire/procedura/achizitie_directa",
          title: "Atribuire",
          img: I2,
          order: 3,
          SHOW: showAchizitions,
        },
        {
          perm: "nomenclatory",
          path:
            this.getUserRole() == "superadmin"
              ? "/administrare/catalog"
              : "/administrare/institutie",
          title: "Administrare",
          img: ["fas fa-toolbox"],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["userRole"]),
  },
  watch: {
    userRole() {
      this.initAllPermisions();
      if (!roleMap.includes(this.getUserRole())) {
        this.adminWidgetsIndex = false;
      }

      this.syncWidgets();
    },
    PERMISIONS: {
      handler() {
        this.syncWidgets();
      },
      deep: true,
    },
  },
  methods: {
    syncWidgets() {
      const permisions = Object.entries(this.PERMISIONS);
      const showNomenclatory = roleMap.includes(this.getUserRole());
      const prepareWidgets = [
        {
          path: "/in_curand/4",
          title: "Evaluare",
          img: I1,
          order: 4,
          SHOW: showAchizitions,
        },
      ].filter((e) => e.SHOW !== false);
      const prepareAdminWidgets = [];
      this.widgetMap
        .filter((e) => e.SHOW !== false)
        .forEach((e) => {
          if (!e) return;
          e = { ...e };

          if (typeof e.perm == "function") {
            e.perm = e.perm.call(this) || "";
          }
          if (typeof e.path == "function") {
            e.path = e.path.call(this) || "";
          }
          if (
            !e ||
            e.perm == "nomenclatory" ||
            (e.perm == "departments" && this.isSuperadmin)
          ) {
            return;
          }

          const routes = e.path.split("|");
          let accesIndex = 0;
          if (
            e.perm === true ||
            (() => {
              for (const i of e.perm.split("|")) {
                const find = permisions.find((e) => e[0] == i);
                if (find && find[1]) {
                  return true;
                }
                accesIndex++;
              }
              return false;
            })()
          ) {
            if (e.forAdmin) {
              prepareAdminWidgets.push({
                ...e,
                path: routes[accesIndex] || "/",
              });
            } else if (prepareWidgets.length < 7) {
              prepareWidgets.push({ ...e, path: routes[accesIndex] || "/" });
            }
          }
        });
      prepareWidgets.push(...prepareAdminWidgets);

      let prep = prepareWidgets.sort((a, b) => a.order - b.order);
      if (showNomenclatory) {
        prep.splice(
          5,
          1,
          this.widgetMap.find((e) => e.perm == "nomenclatory")
        );

        if (this.getUserRole() !== "responsabil_achizitii") {
          prep = prep.filter(
            (e) => e.perm == "nomenclatory" || e.perm == "profile"
          );
        }
      }

      this.widgets = prep;
    },
  },
  created() {
    this.initAllPermisions();
    this.updateRoleStatus();
  },
};
</script>

<style>
.home-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.home-card-wrapper {
  min-width: 230px;
  width: 33.3%;
  padding: 24px;
}

.home-card {
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
}
@media screen and (min-width: 1200px) {
  .home-cards {
    padding: 60px;
  }
}
</style>
